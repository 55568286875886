import { createApp } from "vue";
import App from "./App.vue";
import "./style.css";
import router from "../router";
import Vue3Lottie from "vue3-lottie";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import VueGtag from "vue-gtag";
import { createI18n } from "vue-i18n"; // Import untuk vue-i18n di Vue 3

library.add(faUserSecret);
library.add(faCoffee);
library.add(faFilter);

const i18n = createI18n({
  locale: "en", // Bahasa default
  fallbackLocale: "en",
});

// // Fungsi untuk mendeteksi bahasa perangkat dan mengatur bahasa default
// function detectLanguage() {
//   const languages = navigator.languages || [
//     navigator.language || navigator.userLanguage,
//   ];
//   // Cari bahasa yang mengandung "id"
//   const isIndonesian = languages.some((lang) => /^id\b/.test(lang));
//   return isIndonesian ? "id" : "en"; // Atur ke bahasa Indonesia jika terdeteksi
// }

// // Buat instance i18n dengan bahasa default sesuai bahasa perangkat
// const i18n = createI18n({
//   locale: detectLanguage(), // Atur bahasa default berdasarkan bahasa perangkat
//   fallbackLocale: "en",
//   messages,
// });

const app = createApp(App);

app.use(i18n); // Gunakan i18n dengan instance `app` di Vue 3
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router);
app.use(Vue3Lottie);
app.use(VueGtag, {
  config: { id: import.meta.env.VITE_GOOGLE_ANALYTICS_KEY },
});
app.config.globalProperties.$STATIC_ASSET_BASE_URL =
  "https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com";
app.mount("#app");
