<template>
  <head>
    <link rel="preload" href="../assets/icon/octa-logo.png" as="image" />
    <link rel="preload" href="../assets/octa/octa-left-side.png" as="image" />
  </head>
  <navbar-hide />
  <div class="snap-y snap-mandatory h-screen w-screen overflow-scroll">
    <!-- section 1 -->
    <div
      class="snap-always snap-center bg-section-1 bg-cover bg-center h-screen"
    >
      <div class="flex flex-col md:flex-row h-screen w-screen">
        <!-- Bagian Kiri (Desktop) / Bagian Atas (Mobile) -->
        <div
          class="flex items-center justify-center w-full h-2/5 md:h-full md:w-1/2"
        >
          <div class="text-center font-medium pt-32 px-5 md:pt-0 z-10">
            <div class="text-white text-lg md:text-3xl">
              <div v-html="$t('unleash')"></div>
            </div>
            <div class="flex justify-center">
              <img
                rel="preload"
                class="h-24"
                :src="`${assetBaseUrl}/image/icons/octa-logo.png`"
                alt="Logo"
              />
            </div>
            <div v-html="$t('yourStyle')"></div>
          </div>
        </div>

        <!-- Bagian Kanan (Desktop) / Bagian Bawah (Mobile) -->
        <div
          class="flex items-center justify-center w-full h-3/5 md:h-full md:w-1/2"
        >
          <img
            rel="preload"
            class="w-4/5 md:pt-20"
            :src="`${assetBaseUrl}/image/octa/octa-left-side.png`"
            alt="Product Image"
          />
        </div>
      </div>
    </div>

    <!-- Video -->
    <div
      class="snap-always snap-center bg-black bg-cover bg-center h-screen relative text-white flex flex-col justify-center items-center"
    >
      <video
        ref="videoPlayer"
        class="w-full inset-0 h-screen lg:object-cover object-contain"
        muted
        playsinline
        autoplay
        @ended="replayVideo"
        preload="none"
      >
        <source
          :src="`${assetBaseUrl}/video/octa-video.mp4`"
          type="video/mp4"
        />
      </video>
    </div>

    <!-- new spec start-->
    <div
      class="snap-always snap-center bg-[#1E2A38] bg-cover bg-center h-screen relative text-white flex flex-col justify-center items-center"
    >
      <div class="text-center font-bold pt-8">
        <h1 class="text-3xl md:text-4xl">{{ $t("spesification") }}</h1>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-3 py-4">
        <div class="flex items-center p-4">
          <img
            :src="`${assetBaseUrl}/image/icons/1.png`"
            alt="Image 1"
            class="md:w-16 md:h-16 w-10 h-10 md:mr-4 mr-2"
          />
          <div>
            <p class="md:text-base text-sm">{{ $t("battery") }}</p>
            <h2 class="md:text-lg text-md font-semibold">72 Volt</h2>
            <!-- Changed to <h2> -->
          </div>
        </div>
        <div class="flex items-center p-4">
          <img
            :src="`${assetBaseUrl}/image/icons/3.png`"
            alt="Image 2"
            class="md:w-16 md:h-16 w-10 h-10 md:mr-4 mr-2"
          />
          <div>
            <p class="md:text-base text-sm">{{ $t("tyres") }}</p>
            <h2 class="md:text-lg text-md font-semibold">Tubeless</h2>
            <!-- Changed to <h2> -->
          </div>
        </div>
        <div class="flex items-center p-4">
          <img
            :src="`${assetBaseUrl}/image/icons/4.png`"
            alt="Image 3"
            class="md:w-16 md:h-16 w-10 h-10 md:mr-4 mr-2"
          />
          <div>
            <p class="md:text-base text-sm">{{ $t("speed") }}</p>
            <h2 class="md:text-lg text-md font-semibold">70 KMPH</h2>
            <!-- Changed to <h2> -->
          </div>
        </div>
        <div class="flex items-center p-4">
          <img
            :src="`${assetBaseUrl}/image/icons/2.png`"
            alt="Image 4"
            class="md:w-16 md:h-16 w-10 h-10 md:mr-4 mr-2"
          />
          <div>
            <p class="md:text-base text-sm">{{ $t("motor") }}</p>
            <h2 class="md:text-lg text-md font-semibold">1500</h2>
            <!-- Changed to <h2> -->
          </div>
        </div>
        <div class="flex items-center p-4">
          <img
            :src="`${assetBaseUrl}/image/icons/6.png`"
            alt="Image 5"
            class="md:w-16 md:h-16 w-10 h-10 md:mr-4 mr-2"
          />
          <div>
            <p class="md:text-base text-sm">{{ $t("transmission") }}</p>
            <h2 class="md:text-lg text-md font-semibold">Hub Drive</h2>
            <!-- Changed to <h2> -->
          </div>
        </div>
        <div class="flex items-center p-4">
          <img
            :src="`${assetBaseUrl}/image/icons/5.png`"
            alt="Image 6"
            class="md:w-16 md:h-16 w-10 h-10 md:mr-4 mr-2"
          />
          <div>
            <p class="md:text-base text-sm">{{ $t("cooling") }}</p>
            <h2 class="md:text-lg text-md font-semibold">Air Cooled</h2>
            <!-- Changed to <h2> -->
          </div>
        </div>
      </div>

      <img
        class="p-4 md:h-80"
        :src="`${assetBaseUrl}/image/octa/octa-multi.png`"
        alt=""
      />
    </div>

    <!-- new spec end -->

    <!-- detail -->
    <div
      class="snap-always snap-start bg-white bg-cover bg-center md:h-screen md:flex justify-center items-center"
    >
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
        <!-- Card 1 -->
        <div class="bg-white rounded-lg shadow-md overflow-hidden">
          <img
            class="w-full h-56 md:h-48 object-cover"
            :src="`${assetBaseUrl}/image/octa/1a.png`"
            alt="Image 1"
          />
          <div class="p-4">
            <h3 class="text-xl font-semibold mb-2">{{ $t("title1") }}</h3>
            <p class="text-gray-700">{{ $t("desc1") }}</p>
          </div>
        </div>

        <!-- Card 2 -->
        <div class="bg-white rounded-lg shadow-md overflow-hidden">
          <img
            class="w-full h-56 md:h-48 object-cover"
            :src="`${assetBaseUrl}/image/octa/2a.png`"
            alt="Image 2"
          />
          <div class="p-4">
            <h3 class="text-xl font-semibold mb-2">{{ $t("title2") }}</h3>
            <!-- Fixed spelling -->
            <p class="text-gray-700">{{ $t("desc2") }}</p>
          </div>
        </div>

        <!-- Card 3 -->
        <div class="bg-white rounded-lg shadow-md overflow-hidden">
          <img
            class="w-full h-56 md:h-48 object-cover"
            :src="`${assetBaseUrl}/image/octa/3a.png`"
            alt="Image 3"
          />
          <div class="p-4">
            <h3 class="text-xl font-semibold mb-2">{{ $t("title3") }}</h3>
            <p class="text-gray-700">{{ $t("desc3") }}</p>
          </div>
        </div>

        <!-- Card 4 -->
        <div class="bg-white rounded-lg shadow-md overflow-hidden">
          <img
            class="w-full h-56 md:h-48 object-cover"
            src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/octa/4a.png"
            alt="Image 4"
          />
          <div class="p-4">
            <h3 class="text-xl font-semibold mb-2">{{ $t("title4") }}</h3>
            <p class="text-gray-700">{{ $t("desc4") }}</p>
          </div>
        </div>

        <!-- Card 5 -->
        <div class="bg-white rounded-lg shadow-md overflow-hidden">
          <img
            class="w-full h-56 md:h-48 object-cover"
            :src="`${assetBaseUrl}/image/octa/5a.png`"
            alt="Image 5"
          />
          <div class="p-4">
            <h3 class="text-xl font-semibold mb-2">{{ $t("title5") }}</h3>
            <!-- Capitalization fixed -->
            <p class="text-gray-700">{{ $t("desc5") }}</p>
          </div>
        </div>

        <!-- Card 6 -->
        <div class="bg-white rounded-lg shadow-md overflow-hidden">
          <img
            class="w-full h-56 md:h-48 object-cover"
            :src="`${assetBaseUrl}/image/octa/6a.png`"
            alt="Image 6"
          />
          <div class="p-4">
            <h3 class="text-xl font-semibold mb-2">{{ $t("title6") }}</h3>
            <!-- Capitalization fixed -->
            <p class="text-gray-700">{{ $t("desc6") }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- detail end -->

    <!-- blue section -->

    <div
      class="snap-always snap-center bg-blue-section bg-cover bg-center h-screen"
    >
      <div class="flex flex-col md:flex-row min-h-screen">
        <!-- Bagian Kiri (Desktop) / Bagian Atas (Mobile) -->
        <div
          class="flex items-center justify-center w-full md:w-3/6 p-4 lg:px-8"
        >
          <div class="text-center font-medium mt-20 md:mt-0 space-y-2">
            <h2
              class="text-white font-bold text-[30px] md:text-[40px] leading-10 mt-4"
            >
              {{ $t("rideIn") }}
            </h2>
            <p class="text-white text-xl">
              {{ $t("descRidein") }}
            </p>
          </div>
        </div>

        <!-- Bagian Kanan (Desktop) / Bagian Bawah (Mobile) -->
        <div class="flex items-center justify-center w-full md:w-3/6 lg:mt-20">
          <div class="grid justify-items-center">
            <img
              class="p-10"
              :src="`${assetBaseUrl}/image/octa/octa-blue-right.png`"
              alt="Product Image"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- color picker section start -->
    <div class="snap-always snap-center bg-cover bg-center h-screen">
      <div
        class="bg-[#ffffff] flex relative items-center overflow-hidden h-screen"
      >
        <div
          class="container mx-auto px-6 flex flex-col md:flex-row relative py-16"
        >
          <div
            class="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 text-center md:text-left md:pt-20 pt-4"
          >
            <!-- <img class="mb-5" src="../assets//icon/octa-logo.webp" alt="" /> -->

            <h2 class="text-2xl md:text-5xl font-bold text-[#333333]">
              {{ $t("spark") }}
            </h2>
            <h2 class="text-2xl md:text-5xl text-[#246aa3] font-bold md:pt-2">
              {{ $t("#OCTAGENERATION") }}
            </h2>
            <p class="md:text-base text-xs text-[#333333] pt-2">
              {{ $t("embrace") }}
            </p>
            <div class="text-center space-y-2 md:text-left">
              <p class="md:text-base text-xs text-[#333333] md:pt-10 pt-4">
                {{ $t("price") }}
              </p>
              <p
                class="md:text-base text-xs font-medium text-[#333333] md:pt-2"
              >
                {{ $t("price1") }}
              </p>
              <p class="md:text-base text-xs font-medium text-[#333333]">
                {{ $t("price2") }}
              </p>
              <p class="text-xs text-[#333333]">*OTR Jakarta</p>
            </div>
            <div class="text-center md:text-left md:pt-10 pt-4">
              <p class="text-base text-[#333333]">DEFINE YOUR PERSONALITY</p>
              <p :class="colorClass" class="text-base font-bold">
                {{ colorText }}
              </p>
            </div>

            <div class="flex mt-8"></div>
          </div>
          <div class="sm:block sm:w-1/3 lg:w-3/5 relative">
            <div class="mx-auto flex-row items-center lg:py-24 justify-center">
              <div class="h-fit md:h-full flex justify-center items-center">
                <div v-show="tab === 1">
                  <img
                    :src="`${assetBaseUrl}/image/octa/octa-cyan.png`"
                    class="max-h-56 md:max-h-96"
                    alt=""
                  />
                </div>
                <div v-show="tab === 2">
                  <img
                    :src="`${assetBaseUrl}/image/octa/octa-white.png`"
                    class="max-h-56 md:max-h-96"
                    alt="electric bike"
                  />
                </div>
                <div v-show="tab === 3">
                  <img
                    :src="`${assetBaseUrl}/image/octa/octa-grey.png`"
                    class="max-h-56 md:max-h-96"
                    alt=""
                  />
                </div>
                <div v-show="tab === 4">
                  <img
                    :src="`${assetBaseUrl}/image/octa/octa-army.png`"
                    class="max-h-56 md:max-h-96"
                    alt=""
                  />
                </div>
                <div v-show="tab === 5">
                  <img
                    :src="`${assetBaseUrl}/image/octa/octa-yellow.png`"
                    class="max-h-56 md:max-h-96"
                    alt=""
                  />
                </div>
                <div v-show="tab === 6">
                  <img
                    :src="`${assetBaseUrl}/image/octa/octa-red.png`"
                    class="max-h-56 md:max-h-96"
                    alt=""
                  />
                </div>
                <div v-show="tab === 7">
                  <img
                    :src="`${assetBaseUrl}/image/octa/octa-blue.png`"
                    class="max-h-56 md:max-h-96"
                    alt=""
                  />
                </div>
                <div v-show="tab === 8">
                  <img
                    :src="`${assetBaseUrl}/image/octa/octa-orange.png`"
                    class="max-h-56 md:max-h-96"
                    alt=""
                  />
                </div>
                <div v-show="tab === 9">
                  <img
                    :src="`${assetBaseUrl}/image/octa/octa-black.png`"
                    class="max-h-56 md:max-h-96"
                    alt=""
                  />
                </div>
              </div>

              <div class="color-picker lg:mt-10 pt-4">
                <div class="flex items-center justify-center space-x-2">
                  <button
                    @click="activeTabOne"
                    class="w-8 h-8 bg-[#246aa3] rounded-full"
                    aria-label="Activate tab one with blue color"
                  ></button>
                  <button
                    @click="activeTabTwo"
                    class="w-8 h-8 bg-[#f5f4f4] rounded-full"
                    aria-label="Activate tab two with light grey color"
                  ></button>
                  <button
                    @click="activeTabThree"
                    class="w-8 h-8 bg-[#393939] rounded-full"
                    aria-label="Activate tab three with dark grey color"
                  ></button>
                  <button
                    @click="activeTabFour"
                    class="w-8 h-8 bg-[#0E3F13] rounded-full"
                    aria-label="Activate tab four with dark green color"
                  ></button>
                  <button
                    @click="activeTabFive"
                    class="w-8 h-8 bg-[#FFE500] rounded-full"
                    aria-label="Activate tab five with yellow color"
                  ></button>
                  <button
                    @click="activeTabSix"
                    class="w-8 h-8 bg-[#FF1C1C] rounded-full"
                    aria-label="Activate tab six with red color"
                  ></button>
                  <button
                    @click="activeTabSeven"
                    class="w-8 h-8 bg-[#1200E3] rounded-full"
                    aria-label="Activate tab seven with blue color"
                  ></button>
                  <button
                    @click="activeTabEight"
                    class="w-8 h-8 bg-[#ff6725] rounded-full"
                    aria-label="Activate tab eight with orange color"
                  ></button>
                  <button
                    @click="activeTabNine"
                    class="w-8 h-8 bg-[#1A1A1A] rounded-full"
                    aria-label="Activate tab nine with black color"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- color picker section end -->

    <!-- download app -->
    <div
      class="snap-always snap-center bg-black bg-cover bg-center h-screen md:px-40 flex items-center justify-center"
    >
      <div
        class="flex flex-col-reverse md:flex-row md:space-x-4 w-full md:w-auto"
      >
        <!-- Bagian Kiri (Desktop) / Bagian Atas (Mobile) -->
        <div class="flex items-center justify-center w-full md:w-3/6">
          <div class="text-center md:text-left font-medium mt-4 md:mt-0">
            <p class="text-white text-2xl lg:text-8xl font-bold">
              {{ $t("connect") }}
            </p>

            <p
              class="text-white md:text-lg text-sm lg:text-base md:leading-10 md:mt-4 p-4"
            >
              {{ $t("descConnect") }}
            </p>

            <div
              class="flex space-x-4 md:space-x-4 justify-center md:justify-start mt-10 md:mt-4"
            >
              <a
                href="https://apps.apple.com/id/app/imoto-ev/id6448974269"
                target="_blank"
              >
                <img
                  :src="`${assetBaseUrl}/image/octa/app-store.png`"
                  alt="Download on the App Store"
                  class="w-auto max-h-10"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=app.imoto&pcampaignid=web_share"
                target="_blank"
              >
                <img
                  :src="`${assetBaseUrl}/image/octa/play-store.png`"
                  alt="Download on the Play Store"
                  class="w-auto max-h-10"
                />
              </a>
            </div>
          </div>
        </div>

        <!-- Bagian Kanan (Desktop) / Bagian Bawah (Mobile) -->
        <div
          class="flex items-center justify-center w-full md:w-3/6 mt-10 md:mt-0"
        >
          <div class="grid justify-items-center">
            <img
              class="max-h-full max-w-full h-48 md:h-full md:w-full"
              :src="`${assetBaseUrl}/image/octa/application.png`"
              alt="Product Image"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Test Ride -->
    <div
      class="snap-always snap-start bg-cover bg-center relative flex flex-col justify-center items-center md:p-20"
    >
      <div class="w-full">
        <form-test-ride />
      </div>
    </div>
    <Footer class="snap-normal snap-center bg-center relative" />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import { Vue3Lottie } from "vue3-lottie";
import NavbarHide from "../components/NavbarHide.vue";
import FormTestRide from "../components/FormTestRide.vue";

export default {
  name: "Octa GT",

  components: {
    Footer,
    Animation,
    Vue3Lottie,
    NavbarHide,
    FormTestRide,
  },
  data() {
    return {
      assetBaseUrl: import.meta.env.VITE_STATIC_ASSET_BASE_URL,
      tab: 1,
      lastScrollPosition: 0,
    };
  },

  computed: {
    colorClass() {
      switch (this.tab) {
        case 1:
          return "text-[#246aa3]";
        case 2:
          return "text-[#1A1A1A]";
        case 3:
          return "text-[#393939]";
        case 4:
          return "text-[#0E3F13]";
        case 5:
          return "text-[#FFE500]";
        case 6:
          return "text-[#FF1C1C]";
        case 7:
          return "text-[#1200E3]";
        case 8:
          return "text-[#ff6725]";
        case 9:
          return "text-[#1A1A1A]";
        default:
          return "text-[#246aa3]";
      }
    },
    colorText() {
      switch (this.tab) {
        case 1:
          return "LIGHT BLUE GLOSSY";
        case 2:
          return "WHITE GLOSSY";
        case 3:
          return "DARK GRAY MATT";
        case 4:
          return "GREEN ARMY MATT";
        case 5:
          return "YELLOW GLOSSY";
        case 6:
          return "RED GLOSSY";
        case 7:
          return "BLUE GLOSSY";
        case 8:
          return "ORANGE GLOSSY";
        case 9:
          return "BLACK MATT";
        default:
          return "LIGHT BLUE GLOSSY";
      }
    },
  },

  mounted() {
    this.$refs.videoPlayer.play(); // Autoplay the video when component is mounted
  },
  methods: {
    replayVideo() {
      this.$refs.videoPlayer.play(); // Replay the video
    },
    scrollToDealer() {
      this.$refs.dealerSection.scrollIntoView({ behavior: "smooth" });
    },
    activeTabOne() {
      this.tab = 1;
    },
    activeTabTwo() {
      this.tab = 2;
    },
    activeTabThree() {
      this.tab = 3;
    },
    activeTabFour() {
      this.tab = 4;
    },
    activeTabFive() {
      this.tab = 5;
    },
    activeTabSix() {
      this.tab = 6;
    },
    activeTabSeven() {
      this.tab = 7;
    },
    activeTabEight() {
      this.tab = 8;
    },
    activeTabNine() {
      this.tab = 9;
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
